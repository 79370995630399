<template>
  <b-container fluid>
    <b-row class="mb-2">
      <b-col
        cols="12" md="6"
        lg="4" xl="3"
        class="text-md-left text-center text--white"
      >
        <span class="h4">Employees</span>
      </b-col>
      <b-col
        cols="12" md="6"
        lg="4" offset-lg="4"
        xl="3" offset-xl="6"
        class="text-right"
      >
        <AddEditEmployee @refresh="init()"></AddEditEmployee>
      </b-col>
    </b-row>

    <b-overlay :show="loading">
      <b-card class="card-border text--black q-table-container" style="overflow-x: auto">
        <div class="float-left mb-2">
          Search <b-form-input v-model="text" debounce="500" class="d-inline w-auto"></b-form-input>
        </div>
        <b-pagination
          class="float-right"
          v-if="totalItems > filters.max"
          v-model="filters.page"
          :total-rows="totalItems"
          :per-page="filters.max"
          aria-controls="documentsTable"
          @change="changePage"
        ></b-pagination>
        <b-table
          style="min-width: 1080px;"
          @sort-changed="sortingChanged"
          :items="employees"
          no-local-sorting
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="desc"
          striped
        >
          <template #cell(lastname)="data">
            {{ data.item.firstname }} {{ data.item.lastname }}
          </template>
          <template #cell(lastlogin)="data">
            {{ data.item.lastlogin ? data.item.lastlogin : 'Never' }}
          </template>
          <template #cell(actions)="data">
            <div class="text-right">
              <AddEditEmployee @refresh="init()" :id="+data.item.id" >
                <template>
                  <b-button variant="success"><b-icon icon="pencil-square"></b-icon></b-button>
                </template>
              </AddEditEmployee>
              <div class="d-inline ml-2">
                <b-button
                  @click="disable(data.item)"
                  v-if="data.item.active == 1"
                  variant="danger"
                >
                  <b-icon icon="x-circle"></b-icon>
                </b-button>
                <b-button
                  @click="enable(data.item)"
                  v-else
                  variant="info"
                >
                  <b-icon icon="arrow-clockwise"></b-icon>
                </b-button>
              </div>
              <div class="d-inline ml-2">
                <b-button
                  @click="resetPassword(data.item)"
                  variant="success"
                >
                  <b-icon icon="key-fill"></b-icon>
                </b-button>
              </div>
            </div>
          </template>
        </b-table>
        <b-pagination
          class="float-right"
          v-if="totalItems > filters.max"
          v-model="filters.page"
          :total-rows="totalItems"
          :per-page="filters.max"
          aria-controls="documentsTable"
          @change="changePage"
        ></b-pagination>
        <div class="pl-1 text-left" v-if="totalItems > 10">
          Per page:
          <span
            @click="filters.max = 10"
            :class="filters.max == 10 ? 'active' : ''"
            class="ml-2 link"
          >10</span>
          <span
            href="javasript:void(0)"
            @click="filters.max = 25"
            :class="filters.max == 25 ? 'active' : ''"
            class="ml-2 link"
          >25</span>
          <span
            href="javasript:void(0)"
            @click="filters.max = 50"
            :class="filters.max == 50 ? 'active' : ''"
            class="ml-2 link"
          >50</span>
          <span
            href="javasript:void(0)"
            @click="filters.max = 100"
            :class="filters.max == 100 ? 'active' : ''"
            class="ml-2 link"
          >100</span>
        </div>
      </b-card>
    </b-overlay>
  </b-container>
</template>
<script>
import StorageService from '@/services/StorageService'
import AdminService from '@/services/AdminService'
import AddEditEmployee from '@/components/admin/EmployeeModal'

export default {
  name: 'Admin',
  components: {
    AddEditEmployee
  },
  data () {
    return {
      user: null,
      employees: [],

      filters: {
        max: 25,
        page: 1,
        order: [{
          column: 'lastlogin',
          direction: 'desc'
        }],
        text: '',
        organization_id: null,
        company_id: null
      },

      text: '',

      totalItems: 0,

      sortBy: 'lastlogin',
      desc: true,

      fields: [
        { key: 'lastname', label: 'Name', sortable: true },
        { key: 'email', sortable: true },
        { key: 'lastlogin', label: 'Last Login', sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: '', sortable: false }
      ],

      loading: false
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.search()
    },

    search () {
      this.loading = true
      AdminService.getUsers(this.filters).then((response) => {
        this.employees = []
        this.employees = response.data.info.users
        this.totalItems = response.data.info.totalResults
      }).catch((error) => {
        console.dir(error)
        this.$aimNotify.error(error.response)
      }).finally(() => {
        this.$updateloading(-1)
        this.loading = false
        this.user = StorageService.get('user')
      })
    },

    resetFilters () {
      this.filters = {
        max: 25,
        page: 1,
        order: [{
          column: 'c.updated',
          direction: 'desc'
        }],
        text: '',
        bookkeeper: null,
        active: 1
      }

      this.text = ''

      this.sortBy = 'updated'
      this.desc = true
    },

    changePage (page) {
      this.filters.page = page
    },

    sortingChanged (event) {
      this.filters.page = 1
      this.filters.order = []

      const direction = event.sortDesc ? 'desc' : 'asc'

      if (event.sortBy === 'lastname') {
        this.filters.order.push({
          column: 'lastname',
          direction: direction
        })

        this.filters.order.push({
          column: 'firstname',
          direction: direction
        })
      } else {
        this.filters.order.push({
          column: event.sortBy,
          direction: direction
        })
      }
    },

    disable (user) {
      this.$bvModal.msgBoxConfirm('You are about to disable the user "' + user.firstname + ' ' + user.lastname + '"')
        .then(value => {
          if (value) {
            const data = {
              active: 0,
              id: user.id
            }
            AdminService.updateEmployee(data).then(
              (response) => {
                this.$aimNotify.notify(response)
                this.$nextTick(() => {
                  this.init()
                })
              }
            )
          }
        })
    },

    enable (user) {
      this.$bvModal.msgBoxConfirm('You are about to re-activate the user "' + user.firstname + ' ' + user.lastname + '"')
        .then(value => {
          if (value) {
            const data = {
              active: 1,
              id: user.id
            }
            AdminService.updateEmployee(data).then(
              (response) => {
                this.$aimNotify.notify(response)
                this.$nextTick(() => {
                  this.init()
                })
              }
            )
          }
        })
    },

    resetPassword (user) {
      this.$bvModal.msgBoxConfirm('You are about to reset the password for user "' + user.firstname + ' ' + user.lastname + '"')
        .then(value => {
          if (value) {
            AdminService.resetPassword(user.id).then(
              (response) => {
                this.$aimNotify.notify(response)
              }
            )
          }
        })
    }
  },

  watch: {
    filters: {
      handler: async function (n, o) {
        if (n) {
          if (n.text !== o.text) {
            this.filters.page = 1
          }
          this.search()
        }
      },
      deep: true
    },

    text (n, o) {
      if (n !== o) {
        this.filters.page = 1
        this.filters.text = n
      }
    }
  }
}
</script>
